import React from 'react';
import { graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import { ListProducts } from '../../shared/html/list-products-template';

function ProductList({data, location}) {
  const index = data.localSearchByCodeAndName.index;
  const store = data.localSearchByCodeAndName.store;

  const params = new URLSearchParams(location.search);
  const query = params.get('query');

  const products = useFlexSearch(query, index, store);

  const seoConfig = {
    title: 'Search'
  };

  return ListProducts(products, {}, 'All', seoConfig);
}

export default ProductList;

export const pageQuery = graphql`
query {
  localSearchByCodeAndName {
    index
    store
  }
}
`;
